@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Open+Sans&display=swap');
$purple: #512888;
$orange: #f57b1d;
$title-color: $purple;
$subtitle-color: $purple;
$label-color: $purple;
//$input-placeholder-color: $c5c5c5;
// $breadcrumb-item-separator-color: white;
// $modal-z: 55;
@import '../../node_modules/bulma/bulma.sass';
